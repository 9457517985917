import React, { createContext } from "react";
import { Logout } from "../store/reducers/auth";
import { useDispatch, useSelector } from "../store";
import { getDate } from "../utils/filter";
import axios from "../utils/Axios";
import { saveBets, savePools } from "../store/reducers/p2p";
const APIContext = createContext(null);

export const APIProvider = ({ children }) => {
  const dispatch = useDispatch();
  const state = useSelector((store) => store.auth);
  const { user, currencyId, balanceId, code } = state;
  const userId = user?._id;
  const userName = user?.username;

  const login = async (email, password) => {
    const data = await axios.post("api/v2/users/signin", {
      email,
      password,
    });
    return data;
  };

  const register = async (email, username, password) => {
    const data = await axios.post("api/v2/users/signup", {
      rReferral: code,
      email,
      username,
      password,
    });
    return data;
  };

  const signUpAddress = async (publicAddress, defaultCurrency) => {
    const res = await axios.post("api/v2/users/a-signup", {
      rReferral: code,
      publicAddress,
      defaultCurrency,
    });
    return res;
  };

  const updateUserInfo = async (info) => {
    const res = await axios.post("api/v2/users/info", { ...info, userId });
    return res;
  };

  const logout = () => {
    dispatch(Logout({}));
  };

  const getPaymentCurrencyInfo = async (currency) => {
    const res = await axios.post("api/v2/payments/get-payment-currency-info", {
      currency,
    });

    return res;
  };

  const createNowpayments = async (currencyId, currency, amount) => {

    const res = await axios.post("api/v2/payments/create-nowpay", {
      userId,
      currency,
      currencyId,
      amount,
    });
    return res;
  };

  const getBalances = async () => {
    const res = await axios.post("api/v2/payments/get-balance", { userId });
    return res;
  };

  //currency
  const changeCurrency = async (currency) => {
    const res = await axios.post("api/v2/payments/use-currency", {
      userId,
      currency,
    });
    return res;
  };

  const addCurrency = async (currency) => {
    const res = await axios.post("api/v2/payments/add-currency", {
      userId,
      currency,
    });
    return res;
  };

  const depositMetamask = async (transaction) => {
    const res = await axios.post("api/v2/payments/m-deposit", {
      userId,
      balanceId,
      currencyId,
      ...transaction,
    });
    return res;
  };

  const depositSolana = async (transaction) => {
    const res = await axios.post("api/v2/payments/s-deposit", {
      userId,
      balanceId,
      currencyId,
      ...transaction,
    });
    return res;
  };



  const withdrawal = async (address, method, amount) => {
    const res = await axios.post("api/v2/payments/withdrawal", {
      userId,
      currencyId,
      balanceId,
      address,
      method,
      amount,
    });
    return res;
  };

  //P2P betting
  const createPool = async (data) => {
    const res = await axios.post("api/v2/p2p/create-pool", {
      ...data,
      ownerId: userId,
    });
    return res;
  };

  const joinPool = async (stake, type, poolId) => {
    const res = await axios.post("api/v2/p2p/join-pool", {
      stake,
      type,
      userId,
      poolId,
      currencyId,
    });
    return res;
  };

  const openGame = async (gameId, userId) => {
    const res = await axios.post("api/v2/casinos/opengame", {
      gameId: gameId,
      userId: userId,
    });
    return res;
  };

  const openDemoGame = async (gameId) => {
    const res = await axios.post("api/v2/casinos/demogame", { gameId });
    return res;
  };

  const getPools = async () => {
    const res = await axios.get("api/v2/p2p/get-pools");
    dispatch(savePools(res.data.data));
    return res.data;
  };

  // Dashboard Api
  const getAllSportsProfitByCurrency = async (currency) => {
    let date = getDate();
    // if (currency === 'BCB') {
    //   date = getDate('wed');
    // }
    const res = await axios.post("api/v2/sports/getAllSportsProfitByCurrency", {
      date,
      currency,
    });
    return res;
  };

  const getSportsProfit = async (type) => {
    const date = getDate(type);
    const res = await axios.post("api/v2/sports/getprofit", { date });
    return res;
  };

  const getAllSportsProfit = async () => {
    const res = await axios.get("api/v2/sports/getAllSportsProfit");
    return res;
  };

  const getV2SportsProfit = async (type) => {
    const date = getDate(type);
    const res = await axios.post("api/v2/sports/getv2profit", { date });
    return res;
  };

  const getAllV2SportsProfit = async () => {
    const res = await axios.get("api/v2/sports/getAllV2SportsProfit");
    return res;
  };

  const getAllV3SportsProfit = async () => {
    const res = await axios.get("api/v2/sports/getAllV3SportsProfit");
    return res;
  };

  const getAllV2SportsProfitByCurrency = async (currency) => {
    let date = getDate();
    // if (currency === 'BCB') {
    //   date = getDate('wed');
    // }
    const res = await axios.post(
      "api/v2/sports/getAllV2SportsProfitByCurrency",
      { date, currency }
    );
    return res;
  };

  const getSportStatistics = async (year) => {
    const res = await axios.post(
      "api/v2/sports/getAllV2SportsStatisticByYear",
      { year }
    );
    return res;
  };

  const getUserSportsProfit = async (type) => {
    const date = getDate(type);
    const res = await axios.post("api/v2/sports/getuserprofit", {
      date,
      userId,
    });
    return res;
  };

  const getAllCasinosProfit = async () => {
    let date = getDate("July");
    const res = await axios.post("api/v2/casinos/getAllCasinosProfit", {
      date,
    });
    return res;
  };

  const getCasinoStatistics = async (year) => {
    const res = await axios.post(
      "api/v2/casinos/getAllV2CasinoStatisticByYear",
      { year }
    );
    return res;
  };

  const getOriginalGameStatisticsData = async (year) => {
    const res = await axios.post(
      "api/v2/games/getgameprofit",
      { year }
    );
    return res;
  }

  const getOriginalGamesList = async () => {
    const res = await axios.post(
      "api/v2/games/list"
    );
    return res;
  }

  const getAllOriginalProfitByCurrency = async (currency) => {
    const date = getDate();
    const res = await axios.post(
      "api/v2/casinos/getAllOriginalProfitByCurrency",
      { date, currency }
    );
    return res;
  }
  const getCasinosProfit = async (type) => {
    const date = getDate(type);
    const res = await axios.post("api/v2/casinos/getprofit", { date });
    return res;
  };

  const getAllCasinoProfitByCurrency = async (currency) => {
    let date = getDate();
    // if (currency === 'BCB') {
    //   date = getDate('wed');
    // }
    const res = await axios.post(
      "api/v2/casinos/getAllCasinoProfitByCurrency",
      { date, currency }
    );
    return res;
  };

  const getUserCasinosProfit = async (type) => {
    const date = getDate(type);
    const res = await axios.post("api/v2/casinos/getuserprofit", {
      date,
      userId,
    });
    return res;
  };

  const getCasinoCategory = async () => {
    const res = await axios.get("api/v2/casinos/bo-categorylist");
    return res;
  };

  const getProviderList = async (type) => {
    const res = await axios.post("api/v2/casinos/bo-providerlist", { type });
    return res;
  };

  const uploadFile = async (data) => {
    const res = await axios.post("api/v2/files/", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res;
  };

  const getLeaderboard = async (page, pageSize, userid, date) => {
    const res = await axios.post("api/v2/users/ranking", {
      userId,
      userName,
      pageSize,
      page: page + 1,
      filter: userid,
      date,
    });
    return res;
  };

  const getReferral = async (usreId) => {
    const res = await axios.post("api/v2/users/referral", { userId });
    return res;
  };

  const getTransactions = async (pageIndex) => {
    const res = await axios.post("api/v3/payments/get-transaction", {
      userId,
      pageIndex,
    });
    return res;
  };

  const getGames = async ({ gameType, page, perPage, provider, gameName, mobile }) => {
    const res = await axios.post("api/v2/casinos/bo-gamelist", {
      gameType,
      page,
      perPage,
      provider,
      gameName,
      mobile
    });
    return res;
  };

  const getTopRatedGames = async () => {
    const res = await axios.post("api/v2/casinos/top-gamelist", {});
    return res;
  };

  const cancelWithdrawal = async (_id) => {
    const res = await axios.post("api/v2/payments/c-withdrawal", {
      userId,
      _id,
    });
    return res;
  };

  const getLiveWiners = async () => {
    const res = await axios.get("api/v2/casinos/bo-livewinners");

    return res;
  };

  const getAdminAddress = async () => {
    const res = await axios.post("api/v2/users/getAdminAddress", {});
    return res;
  }

  const getGameId = async (type) => {
    const res = await axios.post("api/v2/wingo/getGameId", {
      data: {
        type
      }
    }
    );

    return res;
  }

  const betColorGameAPI = async (id, colorGameInfo, amount) => {
    const res = await axios.post("api/v2/wingo/betColorGame", {
      data: {
        id, ...colorGameInfo, amount
      }
    }
    );

    return res;
  }

  const getWingoMyRecords = async (data) => {
    const res = await axios.post("api/v2/wingo/getMyRecords", {
      data: {
        ...data,
      }
    }
    );

    return res;
  }

  const getWingoGameRecords = async (data) => {
    const res = await axios.post("api/v2/wingo/getGameRecords", {
      data: {
        ...data,
      }
    }
    );

    return res;
  }

  const getUserInfo = async (data) => {
    const res = await axios.post("api/v2/users/getUserInfo", {
      data: {
        ...data
      }
    })

    return res;
  }

  const getWingoBonusInfo = async () => {
    const res = await axios.post("api/v2/users/getWingoBonusInfo");

    return res;
  }

  const getBonusInfoAPI = async (data) => {
    const res = await axios.post("api/v2/users/getBonusInfo", {
      data: {
        ...data
      }
    })

    return res;
  }

  const applyAllBonusAPI = async (data) => {
    const res = await axios.post("api/v2/users/applyAllBonus", {
      data: {
        ...data
      }
    })

    return res;
  }

  const getCategories = async () => {
    const res = await axios.get("api/v2/wingo/getCategories")
    return res;
  }

  // Get Mine game active status
  const checkMineGameStatus = async (data) => {
    const res = await axios.post("api/v2/mine/status", { userId })
    return res;
  }

  // Create a new mine game
  const createMineGame = async ({ mines, amount, currencyId }) => {
    const res = await axios.post("api/v2/mine/create", {
      userId,
      mines,
      amount,
      currencyId
    })
    return res;
  }

  // Place mine game bet
  const betPlaceMine = async ({ point }) => {
    const res = await axios.post("api/v2/mine/bet", {
      point,
      userId
    })
    return res;
  }

  // Cashout min game
  const cashoutMine = async () => {
    const res = await axios.post("api/v2/mine/cashout", {
      userId
    })
    return res;
  }

  // Place auto mine bet
  const autoBetMine = async ({points, mines, currencyId, amount}) => {
    const res = await axios.post("api/v2/mine/autobet", {
      userId, 
      points,
      mines, 
      currencyId, 
      amount
    })
    return res;
  }

  return (
    <APIContext.Provider
      value={{
        login,
        register,
        getGameId,
        getUserInfo,
        getWingoMyRecords,
        getBonusInfoAPI,
        applyAllBonusAPI,
        getWingoBonusInfo,
        getWingoGameRecords,
        betColorGameAPI,
        getCategories,
        signUpAddress,
        updateUserInfo,
        logout,
        createNowpayments,
        getPaymentCurrencyInfo,
        getBalances,
        changeCurrency,
        addCurrency,
        withdrawal,
        createPool,
        getPools,
        joinPool,
        getAllSportsProfitByCurrency,
        getSportsProfit,
        getAllSportsProfit,
        getV2SportsProfit,
        getAllV2SportsProfit,
        getAllV3SportsProfit,
        getAllV2SportsProfitByCurrency,
        getUserSportsProfit,
        getAllCasinosProfit,
        getSportStatistics,
        getCasinosProfit,
        getAllCasinoProfitByCurrency,
        getUserCasinosProfit,
        getCasinoStatistics,
        openGame,
        openDemoGame,
        getCasinoCategory,
        getProviderList,
        getGames,
        getTopRatedGames,
        uploadFile,
        getLeaderboard,
        getReferral,
        getTransactions,
        cancelWithdrawal,
        depositMetamask,
        depositSolana,
        getLiveWiners,
        getOriginalGameStatisticsData,
        getAllOriginalProfitByCurrency,
        getAdminAddress,
        getOriginalGamesList,
        checkMineGameStatus,
        createMineGame,
        betPlaceMine,
        cashoutMine,
        autoBetMine,
      }}
    >
      {children}
    </APIContext.Provider>
  );
};

export default APIContext;
